import React from 'react'
import Youtube from 'react-youtube'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const StyledYoutube = styled(Youtube)`
  position: relative;
  left: ${p => p.size.left}px;
  top: ${p => p.size.top}px;
  width: ${p => p.size.width}px;
  height: ${p => p.size.height}px;
  right: 0;
  bottom: 0;
`

const PlaceholderImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
  z-index: 1;
`

class YoutubeBackground extends React.Component {
  videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      modestbranding: 1,
      disablekb: 1,
      iv_load_policy: 3,
      playsinline: 1,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      frameDimensions: {},
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions() {
    const containerWidth = this.container.clientWidth
    const containerHeight = this.container.clientHeight
    const containerAspectRatio = containerWidth / containerHeight
    const youtubeLogosBuffer = 1.6

    const containerIsTooWide = containerAspectRatio > this.props.aspectRatio
    const frameDimensions = containerIsTooWide
      ? {
          width: containerWidth,
          height:
            (containerWidth / this.props.aspectRatio) * youtubeLogosBuffer,
        }
      : {
          height: containerHeight * youtubeLogosBuffer,
          width: containerHeight * this.props.aspectRatio,
        }
    frameDimensions.left = -(frameDimensions.width - containerWidth) / 2 // 0 when container is wide
    frameDimensions.top = -(frameDimensions.height - containerHeight) / 2

    this.setState({ frameDimensions })
  }

  onReady(event) {
    this.props.onReady && this.props.onReady()
    event.target.playVideo()
  }
  onPlay() {
    this.props.onPlay && this.props.onPlay()
    // Fix flicker on loop
    setTimeout(() => this.setState({ isPlaying: true }), 100)
  }
  onEnd(event) {
    this.setState({ isPlaying: false })
    this.props.onEnd && this.props.onEnd()
    event.target.playVideo()
  }

  render() {
    const {
      className,
      // to ignore
      aspectRatio,
      onReady,
      onPlay,
      onEnd,
      // to pass through to react-youtube
      ...passThroughProps
    } = this.props

    return (
      <Container
        className={className}
        ref={c => {
          this.container = c
        }}
      >
        <PlaceholderImage
          style={{ opacity: this.state.isPlaying ? 0 : 1 }}
          src={this.props.placeholderSrc}
        />
        <StyledYoutube
          {...passThroughProps}
          onReady={this.onReady.bind(this)}
          onPlay={this.onPlay.bind(this)}
          onEnd={this.onEnd.bind(this)}
          opts={this.videoOptions}
          size={this.state.frameDimensions}
        />
      </Container>
    )
  }
}

YoutubeBackground.defaultProps = {
  aspectRatio: 16 / 9,
}

export default YoutubeBackground
