import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Obfuscate from 'react-obfuscate'

import Banner from './Banner/'
import GlobalStyle, { theme } from './GlobalStyle'
import { WithModal } from './Modal'

const Copy = styled.div`
  section {
    color: ${p => p.theme.colors.darkShade}
    min-height: 10rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${p => p.theme.colors.lightShade};

    &:nth-child(odd) {
      background-color: ${p => p.theme.colors.white};
    }

    > p {
      max-width: 30em;
    }
  }

  footer {
    background-color: ${p => p.theme.colors.darkShade};
    color: ${p => p.theme.colors.white};
    font-size: 0.5em;
  }
`

const Button = styled.button`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  border: 0;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  margin: 1em;

  background: ${p => p.theme.colors.main};
  color: ${p => p.theme.colors.lightShade};

  &:hover,
  &:focus {
    background: ${p => p.theme.colors.darkAccent};
  }

  &:focus {
    outline: 0.25em auto ${p => p.theme.colors.main};
    outline-offset: -0.25em;
  }

  &:active {
    transform: scale(0.97);
  }
`

const P = styled.p`
  max-width: 30em;
`

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Copy>
          <GlobalStyle />
          <Banner />
          <section>
            <h2>Bespoke software solutions</h2>
          </section>
          <section>
            <p>
              We're open to new clients for 2019. Contact us today to talk about
              what you and your business needs to make it more profitable.
            </p>
          </section>
          <section>
            <WithModal
              fit
              modal={
                <P>
                  We'd love to hear from you and have a chat about what you
                  need. Contact us at{' '}
                  <Obfuscate email="contact@unseenengine.com" />
                </P>
              }
            >
              {open => <Button onClick={open}>Contact</Button>}
            </WithModal>
          </section>
          <section>
            <h3>Charities</h3>
            <p>
              Are you a registered charity or non profit with humanitarian,
              social justice or environmental goals? You may be eligible for our
              reduced rates or, in some cases, free service. Contact us to have
              a chat about what you do or what you need.
            </p>
          </section>
          <footer>
            Made with{' '}
            <span role="img" aria-label="heart emoji">
              ❤️
            </span>
            {' and '}
            <span role="img" aria-label="gear emoji">
              ⚙️
            </span>{' '}
            by Unseen Engine © 2019
          </footer>
        </Copy>
      </ThemeProvider>
    )
  }
}

export default App
