import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'

import cacheWoff from './resources/Cache-Regular.woff'

// 1rem = 16px - 24px depending on the viewport width
// leading / magic number / line height / paddings / margins / spacing
const leading = 1.5 // rem

const mediaBoundaries = {
  tiny: 312 /* px = leading * 13 */,
  phone: 600 /* px = leading * 25 */,
  tabletPortrait: 888 /* px = leading * 37 */,
  tabletLandscape: 1200 /* px = leading * 50 */,
  maxSupported: 1440 /* px = leading * 60 */,
}

const mediaQueries = {
  smallerOrEqualTo: Object.keys(mediaBoundaries).reduce((obj, boundary) => {
    obj[boundary] = (...args) => css`
      @media (max-width: ${mediaBoundaries[boundary] - 1}px) {
        ${css(...args)};
      }
    `
    return obj
  }, {}),
  biggerThan: Object.keys(mediaBoundaries).reduce((obj, boundary) => {
    obj[boundary] = (...args) => css`
      @media (min-width: ${mediaBoundaries[boundary]}px) {
        ${css(...args)};
      }
    `
    return obj
  }, {}),
  boundaries: mediaBoundaries,
}

const theme = {
  media: mediaQueries,
  colors: {
    white: 'white',
    lightShade: '#ebe3e7',
    lightAccent: '#49beda',
    main: '#1b62aa',
    darkAccent: '#676073',
    darkShade: '#1b1621',
    //black: 'black',
    //gold: '#b39336',
    //background: 'white',
    //backgroundGrey: '#f2f2f2',
    //red: 'red',
    //textGrey: '#757575',
    //textLightGrey: '#a8a8a8',
    //transparentBlack: 'rgba(0, 0, 0, 0.7)',
    //white: 'white',
    //navyBlue: '#003263',
    //greyOnNavyBlue: '#002345',
    //lightBlue: '#428bca',
  },
  lines: {
    //simple: '1px solid #ccc',
    //gold: '1px solid #b39336',
  },
  sizes: {
    //fontSmall: 0.875rem, // 14px
    //fontTiny: 0.625rem, // 10px
  },
  leading: {
    //custom: size => `${leading * size}rem`, // I hope we never need to use this
    //'0.5': `${leading / 2}rem`,
    '1': `${leading}rem`,
    //'2': `${leading * 2}rem`,
    //'6': `${leading * 6}rem`,
    //'8': `${leading * 8}rem`,
    //'10': `${leading * 10}rem`,
    //'20': `${leading * 20}rem`,
    //// Think carefully about the necessity to add another one
    //// Non standard sizes:
    //'3': `${leading * 3}rem`,
    //'4': `${leading * 4}rem`,
  },
  snippets: {},
}

export { theme }

export default createGlobalStyle`
  ${styledNormalize};

  *, *:before, *:after {
    box-sizing: border-box;
    position: relative;
    /*transition: all 0.2s ease;*/
  }

  @font-face {
    font-family: cache;
    src: local('cache'), url(${cacheWoff}) format("woff");
  }

  html {
    //height: 100%;
    font-size: 1em;
    @media (min-width: 312px) {
      font-size: calc(1em + 0.71vw - 2.21px);
    }
    @media (min-width: 1440px) {
      font-size: calc(1em + 8px);
    }
  }

  body {
    margin: 0;
    padding: 0;
    line-height: ${theme.leading[1]};
    font-family: 'Montserrat', sans-serif;

    text-align: center;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
`
