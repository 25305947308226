import React from 'react'
import { Transition, config } from 'react-spring'

const taglines = [
  'powers your company',
  'builds your solutions',
  'fulfills your designs',
  'excites your customers',
  'structures your technology',
  'realizes your dreams',
  'liberates your attention',
  'presents your products',
  'supports your innovation',
]

export default ({ tick }) => {
  const index = ((tick % taglines.length) + taglines.length) % taglines.length
  return (
    <Transition
      config={config.molasses}
      items={index}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {index => props => <span style={props}>{taglines[index]}</span>}
    </Transition>
  )
}
