import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

let rootElement = document.getElementById('root')
if (!rootElement) {
  // For jsdom for testing
  rootElement = document.createElement('div')
}
ReactModal.setAppElement(rootElement)

const Button = styled.button`
  position: absolute;
  right: 0;
  top: 0;

  background: transparent;
  border: none;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;

  &:focus {
    outline: none;
  }

  & line {
    stroke: rgba(0, 0, 0, 0.7);
  }
`

const CloseButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <svg height="10" width="10">
        <line x1="0" y1="0" x2="10" y2="10" style={{ strokeWidth: 2 }} />
        <line x1="10" y1="0" x2="0" y2="10" style={{ strokeWidth: 2 }} />
      </svg>
    </Button>
  )
}

const Div = styled.div`
  height: 100%;

  > * {
    pointer-events: initial;
  }
`

const Modal = ({ isOpen, onClose, children, fit }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
      overlay: Object.assign(
        {
          cursor: 'pointer',
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        fit && {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      ),
      content: Object.assign(
        {
          cursor: 'initial',
        },
        fit && {
          position: 'relative',
          top: 'initial',
          bottom: 'initial',
          left: 'initial',
          right: 'initial',
        }
      ),
    }}
    contentLabel={'Modal'}
  >
    <CloseButton onClick={onClose} />
    <Div>{children}</Div>
  </ReactModal>
)

class ModalOnce extends React.Component {
  // This component should be used with a key, so that it doesn't reuse the same component when the props change.
  constructor(props) {
    super(props)
    this.state = { isOpen: true }
    this.handleClose = this.handleClose.bind(this)
  }
  handleClose() {
    this.setState({ isOpen: false })
  }
  render() {
    const { children, ...rest } = this.props
    return (
      <Modal {...rest} isOpen={this.state.isOpen} onClose={this.handleClose}>
        {children}
      </Modal>
    )
  }
}

class WithModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }
  handleOpen() {
    this.setState({ isOpen: true })
  }
  handleClose() {
    this.setState({ isOpen: false })
  }
  render() {
    const { children, modal, ...rest } = this.props
    return (
      <React.Fragment>
        <Modal {...rest} isOpen={this.state.isOpen} onClose={this.handleClose}>
          {modal}
        </Modal>
        {children(this.handleOpen)}
      </React.Fragment>
    )
  }
}

export { Modal as default, ModalOnce, WithModal }
