import React from 'react'
import styled from 'styled-components'
import { Spring, config } from 'react-spring'

import YoutubeBackground from './YoutubeBackground'
import AnimatedTagline from './AnimatedTagline'

import videoFirstFrame from './videoFirstFrame.jpeg'

const StyledBanner = styled.header`
  position: relative;
  height: 70vh;
  background-color: rgba(80, 0, 80, 0.2);

  color: white;

  display: flex;
  flex-direction: column;
  justify-content: space-around; /* IE11 */
  justify-content: space-evenly;

  text-transform: uppercase;
  font-family: cache, sans-serif;
  letter-spacing: 0.15em;
  text-shadow: 0 0 0.2em black, 0 0 1em black;
  line-height: 0.85;
`

const H1 = styled.h1`
  font-size: 3rem;
  margin: 0 0.2em;
  text-align: left;
  span {
    display: block;

    &::before {
      content: 'uns';
      visibility: hidden;
    }
  }
`

const FixedText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div:first-child {
    align-self: flex-start;
  }

  div:last-child {
    align-self: flex-end;
  }
  z-index: 10000;
`

const FadingText = styled.div`
  align-self: flex-end;
  font-size: 1.5rem;
  margin: 1rem;

  span {
    white-space: nowrap;
    right: 0;
    position: absolute;
  }
`
const FadeIn = props => (
  <Spring
    config={config.molasses}
    from={{ opacity: 0 }}
    to={{ opacity: 1 }}
    {...props}
  />
)

const The = styled.div`
  margin-top: 0.2em;
`

export default class Banner extends React.Component {
  constructor(props) {
    super(props)
    this.prepareAnimation = this.prepareAnimation.bind(this)
    this.startAnimation = this.startAnimation.bind(this)
    this.tickAnimation = this.tickAnimation.bind(this)
    this.state = {}

    this.prepareAnimationTimeout = setTimeout(
      () => this.prepareAnimation('PRE'),
      1000
    )
  }

  prepareAnimation(isPreAnimation) {
    this.prepareAnimationTimeout && clearTimeout(this.prepareAnimationTimeout)
    this.startAnimationTimeout && clearTimeout(this.startAnimationTimeout)
    this.tickAnimationInterval && clearInterval(this.tickAnimationInterval)
    this.startAnimationTimeout = setTimeout(
      () => this.startAnimation(isPreAnimation),
      500
    )
  }

  startAnimation(isPreAnimation) {
    this.setState({ animationIndex: 0 })
    this.tickAnimationInterval = setInterval(
      this.tickAnimation.bind(this, isPreAnimation),
      5000
    )
  }

  tickAnimation(isPreAnimation) {
    this.setState({
      animationIndex: this.state.animationIndex + (isPreAnimation ? -1 : 1),
    })
  }

  render() {
    return (
      <StyledBanner>
        <YoutubeBackground
          videoId="y_KnQ41pWxk"
          placeholderSrc={videoFirstFrame}
          onPlay={this.prepareAnimation}
        />
        <FixedText>
          <FadeIn>{style => <The style={style}>The</The>}</FadeIn>
          <FadeIn delay={500}>
            {style => (
              <H1 style={style}>
                Unseen
                <span>Engine</span>
              </H1>
            )}
          </FadeIn>
          <FadeIn delay={1000}>{style => <div style={style}>that</div>}</FadeIn>
        </FixedText>
        <FadingText>
          <AnimatedTagline tick={this.state.animationIndex} />
        </FadingText>
      </StyledBanner>
    )
  }
}
